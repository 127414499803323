import React from "react";
import { useState, useEffect, useCallback } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Collapsible from "react-collapsible";

import {
  checkArray,
  checkNull,
  getTypeOfVoucher,
  pluckArray,
} from "../../helpers";
import { voucherPngIcon } from "../../Assets";
import { VoucherTabbar } from "../Components/Tabbar";
import PromotionDetails from "../Components/PromotionDetails";
import XgatePromoDetails from "../Components/XgatePromoDetails";
import { toggleXgatePointsPopup } from "../../Redux/actions";

function Vouchers(params) {
  const dispatch = useDispatch()
  const tableReducer = useSelector((state) => state.tableDetailReducer);
  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const userDetail = useSelector(
    (state) => state?.authDetailReducer?.user_detail
  );
  const cartDetail = useSelector(
    (state) => state?.cartDetailReducer?.cart_detail
  );
  const voucherListDetail = useSelector(
    (state) => state?.cartDetailReducer?.voucher_list_detail?.VoucherLists
  );
   const {
    xgate_details: xgateDetails,
    xgate_coupons: xgateCoupons,
    como_details: comoDetails,
    eber_details:eberDetails
  } = useSelector((state) => state?.authDetailReducer);
  const applied_voucher = useSelector(
    (state) => state.cartDetailReducer?.applied_voucher
  );
  const Promotions = tableReducer?.table_detail?.Promotions;


  const [activeTab, setActiveTab] = useState(0);
  const [pluckPromotionCode, setPluckPromotionCode] = useState([]);
  const redeemPoints = parseFloat(
    xgateDetails?.membership?.point_balance?.[0]?.balance ?? 0
  );

  useEffect(() => {
    if (userDetail?.promotions && userDetail?.promotions.length > 0) {
      setPluckPromotionCode(pluckArray(userDetail.promotions, "code"));
    }
  }, []);

  const switchTab = useCallback(
    (value) => {
      setActiveTab(value);
    },
    [activeTab]
  );

  const recordNotFound = (
    <div className="promotion_details text-center">
      <p>no records found</p>
    </div>
  );

  function getProtionItems() {
    let res = recordNotFound;
    if (checkArray(xgateCoupons?.coupon_codes)) {
      res = xgateCoupons?.coupon_codes
        .filter(
          (p) =>
            p.status_description == "ISSUED" || p.status_description == "NEW"
        )
        .map((coupon_code, couponInx) => {
          return (
            <XgatePromoDetails
              voucher={coupon_code}
              applied_voucher={[]}
              activeTab={activeTab}
              isValid={
                cartDetail !== null && cartDetail.length > 0 ? true : false
              }
              merchantId={merchantId}
              key={couponInx}
            />
          );
        });
    } else if (voucherListDetail && voucherListDetail !== null) {
      res = voucherListDetail
        ?.filter(
          (p) =>
            pluckPromotionCode.indexOf(p?.TypeValue) < 0 &&
            p?.IsRedeemable == true
        )
        ?.map((voucher, index) => (
          <PromotionDetails
            voucher={voucher}
            applied_voucher={applied_voucher}
            activeTab={activeTab}
            isValid={
              cartDetail !== null && cartDetail.length > 0 ? true : false
            }
            merchantId={merchantId}
            key={index}
          />
        ));
    }
    else if (comoDetails?.membership?.assets.length) {
      res = comoDetails?.membership?.assets
        ?.filter(
          (p) =>
           p?.redeemable == false
        )
        ?.map((voucher, index) => (
          <PromotionDetails
            voucher={voucher}
            applied_voucher={applied_voucher}
            activeTab={activeTab}
            isValid={
              cartDetail !== null && cartDetail.length > 0 ? true : false
            }
            merchantId={merchantId}
            key={index}
          />
        ));
    }
    else if (eberDetails?.redeemable_list?.length) {
      res = eberDetails?.redeemable_list
        // ?.filter(
        //   (p) =>
        //    p?.redeemable == false
        // )
        ?.map((voucher, index) => (
          <PromotionDetails
            voucher={voucher}
            applied_voucher={Promotions}
            activeTab={activeTab}
            isValid={
              cartDetail !== null && cartDetail.length > 0 ? true : false
            }
            merchantId={merchantId}
            key={index}
          />
        ));
    }
    // if (voucherListDetail !== null) {
    //   if (activeTab === 0 && voucherListDetail?.ActiveVoucherLists !== null) {
    //     res = voucherListDetail?.ActiveVoucherLists.map((voucher, index) => (
    //       <PromotionDetails
    //         voucher={voucher}
    //         activeTab={activeTab}
    //         key={index}
    //       />
    //     ));
    //   } else if (
    //     activeTab === 1 &&
    //     voucherListDetail?.RedeemedVoucherLists !== null
    //   ) {
    //     res = voucherListDetail?.RedeemedVoucherLists.map((voucher, index) => (
    //       <PromotionDetails
    //         voucher={voucher}
    //         activeTab={activeTab}
    //         key={index}
    //       />
    //     ));
    //   } else if (
    //     activeTab === 2 &&
    //     voucherListDetail?.ExpiredVoucherLists !== null
    //   ) {
    //     res = voucherListDetail?.ExpiredVoucherLists.map((voucher, index) => (
    //       <PromotionDetails
    //         voucher={voucher}
    //         activeTab={activeTab}
    //         key={index}
    //       />
    //     ));
    //   }
    // }
    return res;
  }

  return (
    <section className="info-sec">
      <div className="history-info" id="vouchers">
        <h4 className="title"> Perks </h4>
        {/* <VoucherTabbar activeTab={activeTab} switchTab={switchTab} /> */}
        {getProtionItems()}
        {/* <div className="promotion_details">
          <button
            className={`green-btn redeemBtn ${redeemPoints > 0 ? "" : "disabled"}`}
            onClick={() => {
              if(redeemPoints>0)
              dispatch(toggleXgatePointsPopup(true));
            }}
          >
            {`Redeem Points`}
          </button>
        </div> */}

        {/* {cardDetail?.VoucherLists?.filter((p) =>
          getTypeOfVoucher(activeTab, p)
        )?.map((voucher, index) => (
          <PromotionDetails voucher={voucher} key={index} />
        ))}
        {(cardDetail?.VoucherLists == undefined ||
          cardDetail?.VoucherLists?.filter((p) =>
            getTypeOfVoucher(activeTab, p)
          )?.length <= 0) &&
          recordNotFound} */}
      </div>
    </section>
  );
}

export default Vouchers;

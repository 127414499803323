import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCartNewItemCount } from "../../helpers";
import { StyledSVG } from "../../Utils/style";
import { cart, left_arrow_green } from "../../Assets";

function CartHeader({ backTitle, rtComponent, backPath, onTabProfile, style }) {
  const history = useHistory();
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;
  const cartCount = getCartNewItemCount(cart_detail);
  return (
    <header style={style}>
      <div className="container">
        <div className="row">
          <div onClick={() => backPath()} className="col-6 d-flex align-items-center ">
            <StyledSVG
                color={table_detail?.QrOrdering?.brand_highlight_color}
                src={left_arrow_green}
                width="8"
              />{" "}
            <a
              href="#"
              className="back"
              onClick={() => backPath()}
              style={{ color: table_detail?.QrOrdering?.brand_highlight_color }}
            >
              
              {backTitle}{" "}
            </a>
          </div>
          <div className="col-6 notify">
            {/* <a
            
              href="#"
              className="notification"
              style={{
                marginRight: `${loyaltyEnabled ? "20px" : "0px"}`,
               color:table_detail?.QrOrdering?.brand_highlight_color

              }}
            >
              {cartCount > 0 ? (
                <span className="counts"> {cartCount} </span>
              ) : null}
            </a> */}
            <div>
              <StyledSVG
                src={cart}
                className="notification"
                color={table_detail?.QrOrdering?.brand_main_color}
                width={20}
              ></StyledSVG>
              <a
                href="#_"
                style={{
                  color: table_detail?.QrOrdering?.brand_highlight_color,
                  marginRight: `${loyaltyEnabled ? "20px" : "0px"}`,
                }}
              >
                {cartCount > 0 ? (
                  <span className="counts cart-count"> {cartCount} </span>
                ) : null}
              </a>
            </div>
            {loyaltyEnabled && (
              <a
                href="#"
                onClick={() => onTabProfile()}
                className="profile"
                style={{
                  backgroundColor:
                    table_detail?.QrOrdering?.brand_main_color,
                }}
              >
                {" "}
              </a>
            )}
          </div>
        </div>
        {rtComponent ? rtComponent : null}
      </div>
    </header>
  );
}

export default CartHeader;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";

import AccountPopup from "../Components/AccountPopup";
import {
  changePaymentDetail,
  getSelectedItemDetail,
  orderPlaceDetail,
} from "../../Redux/actions";
import {
  // getModifierActionSign,
  // getModifierActionName,
  // checkNegNum,
  // makeNegToPosNum,
  // sortCartItems,
  // getCartBody,
  // parseTwoDigitNumber,
  parseRound,
  getInitialPaymentObj,
  getRandomString,
  checkNull,
  checkArray,
  scrollToDiv,
  percentage,
} from "../../helpers";

import {} from "../../Assets";
import TipPopup from "../Components/TipPopup";
import { APPLY_VOUCHER_DETAIL_RECEIVED } from "../../Redux/actions/types";
import PaymentInfo from "./PaymentInfo";
import PaymentSplitOptions from "./PaymentSplitOptions";
import PaymentSuccess from "./PaymentSuccess";

function Payment({ setShowPaymentPage, style }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const { paymentType, paymentBody, splitPaymentArr } = useSelector(
    (state) => state.paymentDetailReducer
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer.user_detail
  );
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  const applied_voucher = useSelector(
    (state) => state.cartDetailReducer?.applied_voucher
  );
  const applied_promocode = useSelector(
    (state) => state.cartDetailReducer?.applied_promocode
  );
  const total_tip = useSelector((state) => state.cartDetailReducer?.total_tip);
  let defaultDiscountTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;

  const calculateIsToolBarStatus = () => {
    let res = false;
    let maxHeight = window.outerHeight - window.innerHeight;
    if (maxHeight > 120) {
      res = true;
    }
    return res;
  };

  const [slidePaySuccessPage, setSlidePaySuccessPage] = useState(false);
  const [showPaySuccessPage, setShowPaySuccessPage] = useState(false);
  const [successPageState, setSuccessPageState] = useState(null);

  const togglePaySuccessPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showPaySuccessPage) {
        setSlidePaySuccessPage(false);
        setTimeout(() => {
          setShowPaySuccessPage(!showPaySuccessPage);
        }, 500);
      } else {
        setShowPaySuccessPage(!showPaySuccessPage);
        setTimeout(() => {
          setSlidePaySuccessPage(true);
        }, 100);
      }
    },
    [showPaySuccessPage, slidePaySuccessPage, successPageState, paymentType]
  );

  const [totalHeight, setTotalHeight] = useState(window.innerHeight);
  const totalHeightRef = useRef(totalHeight);
  totalHeightRef.current = totalHeight;
  const [isToolBarHide, setIsToolBarHide] = useState(
    calculateIsToolBarStatus()
  );

  useEffect(() => {
    setIsToolBarHide(calculateIsToolBarStatus());
  }, [totalHeight]);

  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount
  );
  const [totalServiceCharge, setTotalServiceCharge] =
    useState(defaultServiceCharge);
  const [totalSaleTax, setTotalSaleTax] = useState(defaultTotalSaleTax);
  const [totalTip, setTotalTip] = useState(`${total_tip}`);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalItemLevelDiscount, setTotalItemLevelDiscount] = useState(0);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const isUpFrontPayment =
    table_detail?.QrOrdering?.payment_required_upfront == "yes" ? true : false;

  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  useEffect(() => {
    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    let defaultPromoDiscount = 0;
    // promotion discount
    if (
      cart_detail &&
      checkArray(
        cart_detail.filter(
          (p) =>
            checkNull(p?.promotions, false) &&
            p?.promotions?.type !== "Re-Price"
        )
      )
    ) {
      cart_detail
        .filter(
          (p) =>
            checkNull(p?.promotions, false) &&
            p?.promotions?.type !== "Re-Price"
        )
        .map((item) => {
          defaultPromoDiscount =
            defaultPromoDiscount +
            parseFloat(item?.promotions?.discountPerItem ?? 0) *
              parseInt(item?.quantity ?? 0);
        });
    }
    // promotion discount END
    let defaultItemLevelDiscount = 0;
    // promotion discount
    if (cart_detail && checkArray(cart_detail)) {
      cart_detail.map((item) => {
        let discountedPrc = item?.discount ?? 0;
        const itemTotalAmt =
          parseFloat(item?.amount ?? 0) * parseFloat(item?.quantity ?? 0);
        if (
          item?.discountType != "amt" &&
          discountedPrc > 0 &&
          itemTotalAmt > 0
        ) {
          discountedPrc = percentage(discountedPrc, itemTotalAmt, 4);
        }
        defaultItemLevelDiscount = defaultItemLevelDiscount + discountedPrc;
      });
    }
    let defaultTotalDiscount = 0;
    //applied_voucher
    if (applied_voucher && applied_voucher.length > 0) {
      const newApplyVoucher = applied_voucher.map((applyVoucher) => {
        if (applyVoucher?.Type == "Cash") {
          const discountAmt = parseFloat(applyVoucher?.TypeValue ?? 0);
          applyVoucher.discountAmt = discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        } else if (applyVoucher?.Type == "Discount") {
          const discountAmt =
            (parseFloat(applyVoucher?.TypeValue ?? 0) *
              defaultDiscountTotalCartAmount) /
            100;
          applyVoucher.discountAmt = discountAmt;
          defaultDiscountTotalCartAmount =
            defaultDiscountTotalCartAmount - discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        }
        return applyVoucher;
      });
      dispatch({
        type: APPLY_VOUCHER_DETAIL_RECEIVED,
        payload: newApplyVoucher,
      });
    }
    if (applied_promocode && applied_promocode !== null) {
      defaultTotalDiscount = defaultTotalDiscount + getPromotionTotalDis();
    }
    // applied_voucher END
    setTotalDiscount(defaultTotalDiscount);
    setPromoDiscount(defaultPromoDiscount);
    setTotalItemLevelDiscount(defaultItemLevelDiscount);
    setTotalSaleTax(newTotalSaleTax);
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
  }, [cart_detail]);

  useEffect(() => {
    dispatch(
      changePaymentDetail({
        paymentType: isUpFrontPayment ? "whole" : "",
        is_payment_started: true,
      })
    );

    window.addEventListener("resize", function (event) {
      setTotalHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener("resize", (e) => {});
      changePaymentDetail({ is_payment_started: false, is_payment_cleared: false });
    };
  }, []);

  useEffect(() => {
    setInitialPaymentDetail();
  }, [paymentType]);

  const getPromotionTotalDis = () => {
    let res = getBasketTotalAmount(true, true);
    let promotionDiscount = 0;
    if (applied_promocode && applied_promocode !== null) {
      if (applied_promocode.type == "Amount") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = res - promotionDiscount;
      } else if (applied_promocode.type == "Percentage") {
        promotionDiscount = (res * parseFloat(applied_promocode.amount)) / 100;
        res = res - promotionDiscount;
      } else if (applied_promocode.type == "Re-Price") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = promotionDiscount;
      }
    }
    return res;
  };

  function getBasketTotalAmount(withoutTip = false, onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalTipCh = withoutTip
        ? 0
        : parseFloat(totalTip.toString().replace("$", ""));
      const totalDisCh = parseFloat(onlyTotal ? 0 : totalDiscount + totalItemLevelDiscount);
      res =
        totalCartAmount +
        totalServiceCharge +
        totalTipCh +
        totalSaleTax -
        totalDisCh;
    }

    if (onlyTotal) {
      return res;
    }
    return (
      <>
        {`${table_detail?.currency ?? ""}`}
        {parseRound(res)}
      </>
    );
  }
  function getCartTotalAmount(withoutTip = false, onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalTipCh = withoutTip
        ? 0
        : parseFloat(totalTip.toString().replace("$", ""));
      const totalDisCh = parseFloat(onlyTotal ? 0 : totalDiscount + totalItemLevelDiscount);
      res =
        totalCartAmount +
        totalServiceCharge +
        totalTipCh +
        totalSaleTax -
        totalDisCh;
    }
    return parseRound(res, 2, true);
  }

  const setInitialPaymentDetail = () => {
    let res = [];
    if (paymentType == "whole") {
      const initialSplitObj = getInitialPaymentObj();
      const payTotal = getCartTotalAmount();
      const payServiceCharge = totalServiceCharge;
      const paySalesTax = totalSaleTax;
      const newSplitObj = {
        ...initialSplitObj,
        splitType: "Split Evenly",
        split_number: 1,
        splitOfflineID: getRandomString(),
        splitPrice: payTotal,
        service_charge: payServiceCharge,
        sales_tax: paySalesTax,
        tips: paymentBody?.tips ?? 0,
      };
      res.push(newSplitObj);
    }
    dispatch(changePaymentDetail({ splitPaymentArr: res }));
  };

  const getTipContainer = () => {
    let res = null;
    if (paymentBody?.tips && paymentBody?.tips > 0 && paymentType == "whole") {
      res = (
        <li>
          <span> Tip </span>
          <strong>
            <small>{`${table_detail?.currency ?? ""}`}</small>
            {parseRound(paymentBody?.tips)}
          </strong>
        </li>
      );
    }
    return res;
  };

  function getCartDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <>
          <div
            className={`wrapper cart_wrapper cart_sidebar payment-con order-summary ${
              isToolBarHide ? "" : "toolbar-vh"
            }`}
            onScroll={scrolledElement}
            {...handlers}
            style={style}
          >
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 130 : 0, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <Header
                  backTitle={"Back"}
                  backPath={setShowPaymentPage}
                  showIcons={false}
                  style={{ height: `${x}px` }}
                  rtComponent={
                    <>
                      <div className="hotel_name">
                        <div className="circle-img">
                          <figure className="">
                            <ImageTag
                              src={table_detail?.Merchant?.logo}
                              alt=""
                            />
                          </figure>
                        </div>
                        <div className="naming-sec text-center">
                          <h4> {table_detail?.Merchant?.account_name} </h4>
                          <p>
                            Table #{table_detail?.PosTableMaster?.table_number}{" "}
                            Basket
                          </p>
                        </div>
                      </div>
                    </>
                  }
                />
              )}
            </Motion>
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 0 : 60, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <Header
                  backTitle={"Back"}
                  backPath={setShowPaymentPage}
                  showIcons={false}
                  style={{
                    height: `${x}px`,
                    position: "fixed",
                    top: "0px",
                    width: "100%",
                    zIndex: 9,
                    right: `${style.right}`,
                    backgroundColor: "#f5f6f7",
                  }}
                  rtComponent={<></>}
                />
              )}
            </Motion>
            <section
              id={'middle-container'}
              className={`middle-container ${hideHeader ? "scrollUpTwo" : ""}`}
            >
              <h3 class="title pt-0"> Order Summary </h3>
              <ul class="billing-list mx-n3 px-4 py-2 my-0">
                <li>
                  <span> Sub Total </span>
                  <strong>
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {`${parseRound(
                      totalCartAmount 
                      // + totalDiscount + promoDiscount + totalItemLevelDiscount
                    )}`}
                  </strong>
                </li>
                <li>
                  <span> Discounts </span>
                  <strong>
                    (
                      {/* {totalDiscount + promoDiscount > 0 ? "-" : ""} */}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {`${parseRound(totalDiscount + promoDiscount + totalItemLevelDiscount)}`})
                  </strong>
                </li>
                <li>
                  <span> Service Charges </span>
                  <strong>
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {parseRound(totalServiceCharge)}
                  </strong>
                </li>
                <li>
                  <span> Tax </span>
                  <strong>
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {parseRound(totalSaleTax)}
                  </strong>
                </li>
                {getTipContainer()}
                <li class="total border-bottom-0 border-top-2">
                  <span> Total </span>
                  <strong>
                    {getBasketTotalAmount(
                      paymentType == "whole" ? false : true
                    )}
                  </strong>
                </li>
              </ul>

              <h3 class="title"> Pay Your Bill </h3>
              <div className="billing-list mx-n3 px-4 py-5 my-0">
                <p> How would you like to settle the bill? </p>

                <button
                  className={`blank_btn${
                    paymentType == "whole" ? " active" : ""
                  }`}
                  onClick={() => {
                    dispatch(
                      changePaymentDetail({
                        paymentType: "whole",
                        splitType: 0,
                      })
                    );
                  }}
            style={{border:`2px solid ${ paymentType == "whole"?table_detail?.QrOrdering?.brand_main_color:"#D8D8D8"}`}}

                >
                  {" "}
                  Pay whole bill{" "}
                </button>
                <button
                  className={`blank_btn mb-2${
                    paymentType == "split" ? " active" : ""
                  }`}
                  disabled={isUpFrontPayment}
                  // disabled={true}
            style={{border:`2px solid ${ paymentType == "split"?table_detail?.QrOrdering?.brand_main_color:"#D8D8D8"}`}}


                  onClick={() => {
                    if (!isUpFrontPayment){
                      setTimeout(() => {
                        scrollToDiv("middle-container");
                      }, 100);
                      dispatch(changePaymentDetail({ paymentType: "split" }));
                    }
                  }}
                >
                  {" "}
                  <span className="split-icon"> </span> Split bill{" "}
                </button>
              </div>
              {paymentType == "whole" && checkArray(splitPaymentArr) && (
                <PaymentInfo
                  cartTotalAmount={getCartTotalAmount()}
                  cartAmount={getCartTotalAmount(true)}
                  splitPayIndex={0}
                  totalTip={parseFloat(totalTip.toString().replace("$", ""))}
                  togglePaySuccessPopUp={togglePaySuccessPopUp}
                  setSuccessPageState={setSuccessPageState}
                  setShowPaymentPage={setShowPaymentPage}
                />
              )}
              {paymentType == "split" && (
                <PaymentSplitOptions
                  totalAmount={getCartTotalAmount(true)}
                  totalServiceCharge={totalServiceCharge}
                  totalSaleTax={totalSaleTax}
                />
              )}
            </section>
          </div>
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePaySuccessPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPaySuccessPage ? (
                <PaymentSuccess
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPaySuccessPage={togglePaySuccessPopUp}
                  successPageState={successPageState}
                />
              ) : null;
            }}
          </Motion>
        </>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default Payment;

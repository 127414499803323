import React, { useRef } from "react";
import { useState } from "react";
import Collapsible from "react-collapsible";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  addPromocodeDetail,
  emptyPromocodeDetail,
  getApplyVoucherDetail,
  removeItemVoucherDetail,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import {
  capitalizeFirstLetter,
  nFormatter,
  getUnixDateFormat,
  checkArray,
  checkVoucherAvailable,
  pluckArray,
} from "../../helpers";
import { voucherPngIcon } from "../../Assets";
import moment from "moment/moment";

function PromotionDetails({
  applied_voucher,
  voucher,
  activeTab,
  isValid,
  merchantId,
}) {
  const dispatch = useDispatch();
  // console.log("applied_voucher-----------",applied_voucher)
  const table_detail = useSelector((state) => state.tableDetailReducer);

  // console.log("tableDetails .reducers data ",table_detail)
  

  const { cart_detail,applied_promocode} = useSelector(
    (state) => state.cartDetailReducer
  );
  // const applied_voucher_cart_data = useSelector(
  //   (state) => state?.cartDetailReducer?.applied_voucher
  // );

  const [open, setOpen] = useState(false);
  const voucherKey =
    applied_voucher.length > 0
      ? applied_voucher.findIndex((p) => p?.code == voucher?.pos_redeem_sku)
      : -1;
  // console.log(
  //   "----------------------",
  //   applied_voucher.findIndex((p) => p.code == voucher?.pos_redeem_sku)
  // );
  const isVoucher_applied =   applied_promocode?.code == voucher?.pos_redeem_sku
  
  
  // console.log(applied_voucher_cart_data?.code,"applied_voucher_cart_data----------------------", applied_voucher_cart_data.length);
  // console.log(voucher?.pos_redeem_sku,"voucher?.pos_redeem_sku----------------------");

  // console.log( applied_voucher_cart_data.code == voucher?.pos_redeem_sku,"isVoucher_applied----------------------");

  function getVoucherType(show = false) {
    let res = "$";
    if (voucher?.Type == "Discount") {
      res = "%";
    } else if (voucher?.Type == "VTSKU") {
      res = `${show ? "OFF" : ""}`;
    } else if (voucher?.pos_redeem_method) {
      res = "";
    }

    return res;
  }

  function getVoucherName() {
    let res = "";
    if (voucher?.Type == "Discount") {
      res = "Discount";
    } else if (voucher?.Type == "Cash") {
      res = "Cash";
    } else if (voucher?.pos_redeem_method) {
      res = capitalizeFirstLetter(voucher?.pos_redeem_method);
    }

    return res;
  }

  function getVoucherValue() {
    let res = "OFF";
    if (voucher?.Type == "Discount") {
      res = parseFloat(voucher?.TypeValue);
    } else if (voucher?.Type == "Cash") {
      res = parseFloat(voucher?.TypeValue);
    } else if (voucher?.pos_redeem_percentage) {
      res = parseFloat(voucher?.pos_redeem_percentage);
    } else if (voucher?.pos_redeem_amount) {
      res = parseFloat(voucher?.pos_redeem_amount);
    }

    return res;
  }
  const findValidPromoIndex = (code) =>
  applied_voucher.filter((p) => p?.auto_apply !== "Yes").findIndex(
      (promo) => {
        let res = false;
        if (
          promo?.code == code 
          // &&
          // checkVoucherAvailable(
          //   promo?.datetime,
          //   table_detail,
          //   table_detail?.Merchant?.timezone_offset ?? "00:00"
          // )
        ) {
          res = true;
        }
        return res;
      }
    );
  const checkAppliedPromo = (code) => {
    console.log(applied_voucher,"table_detail?.Promotions", table_detail?.Promotions);
    if (
      checkArray(applied_voucher) &&
      checkArray(
        applied_voucher.filter((p) => p?.auto_apply !== "Yes")
      ) 
      && findValidPromoIndex(code) > -1
    ) {
      const validPromo = applied_voucher.filter(
        (p) => p?.auto_apply !== "Yes"
      )?.[findValidPromoIndex(code)];
      validPromo.identifier=voucher?.redeem_code
      console.log("validPromo---------------",validPromo)
      if (validPromo.qualification_type == "order") {
        dispatch(
          showNotificationWithTimeout(
            `${validPromo?.promotion_name ?? ""} successfully applied`,
            "success"
          )
        );
        dispatch(addPromocodeDetail(validPromo));
        // closePopup();
      } else {
        let isFoundItem = false;
        if (checkArray(validPromo.MenuItem)) {
          const menuItemIds = pluckArray(validPromo.MenuItem, "id");
          let newCart = [...cart_detail];
          newCart = newCart.map((promoItem) => {
            if (
              promoItem?.is_added === false &&
              promoItem?.promotions === undefined &&
              promoItem.id.includes(menuItemIds)
            ) {
              isFoundItem = true;
              const accutalPrice = parseFloat(
                promoItem?.actual_menu_price ?? 0
              );
              let promoPrice = 0;
              let discountPrice = 0;
              if (validPromo.type == "Amount") {
                discountPrice = parseFloat(validPromo.amount);
                promoPrice = accutalPrice - discountPrice;
              } else if (validPromo.type == "Percentage") {
                discountPrice =
                  (accutalPrice * parseFloat(validPromo.amount)) / 100;
                promoPrice = accutalPrice - discountPrice;
              } else if (validPromo.type == "Re-Price") {
                discountPrice = parseFloat(validPromo.amount);
                promoPrice = discountPrice;
              }
              promoItem.promotions = {
                id: validPromo.id,
                type: validPromo.type,
                code: validPromo.code,
                name: validPromo.promotion_name,
                auto_apply: validPromo.auto_apply,
                accutalPrice: accutalPrice,
                promotionTotaldiscount: discountPrice,
                amount: validPromo.amount,
                discountPerItem: parseFloat(discountPrice),
                combo_allowed: validPromo.combo_allowed,
                isLoyaltyVoucher:
                  validPromo?.loyalty_only_promotion == "1" ? "yes" : "no",
                voucherName: "",
              };
              if (promoItem.menu_item_type == "bundled") {
                promoItem.actual_menu_price = promoPrice;
                promoItem.amount = promoPrice;
              }
            }
            return promoItem;
          });
          console.log("checkItmes", newCart);
        }
        if (isFoundItem) {
          dispatch(
            showNotificationWithTimeout(
              `${validPromo?.promotion_name ?? ""} successfully applied`,
              "success"
            )
          );
          dispatch(addPromocodeDetail(validPromo));
          // closePopup();
        } else {
          dispatch(
            showNotificationWithTimeout(`Please add valid items!`, "error")
          );
          dispatch(emptyPromocodeDetail());
        }
      }
    } else {
      dispatch(showNotificationWithTimeout(`Invalid code!`, "error"));
      dispatch(emptyPromocodeDetail());
    }
  };

  return (
    <div className="promotion_details">
      <div className="row mx-0 justify-content-start">
        <div className="squre-img col-4 mx-0 px-0">
          <figure className="">
            {voucher?.image ?? voucher?.image_url ? (
              <img src={voucher?.image ?? voucher?.image_url} alt="" />
            ) : (
              <img src={table_detail?.Merchant?.logo} alt="" />
            )}
          </figure>
        </div>
        <div className="naming-sec col-8 pr-0">
          <h4>
            {" "}
            {voucher?.redeem_name
              ? voucher?.redeem_name
              : voucher?.VoucherTypeName != ""
              ? voucher?.VoucherTypeName
              : "N/A"}{" "}
            {}
          </h4>
          <p>
            <em>
              {" "}
              Expiry Date:{" "}
              {voucher?.ValidTo
                ? getUnixDateFormat(voucher?.ValidTo, "DD/MM/YYYY")
                : voucher?.validUntil
                ? moment(voucher?.validUntil).format("DD/MM/YYYY")
                : "N/A"}{" "}
            </em>
          </p>
          <div className="btns d-flex pt-3">
            <button className="more-btn" onClick={() => setOpen(!open)}>
              {" "}
              More{" "}
            </button>
            {/* {activeTab === 0 && (
              <button
                className={`green-btn btn-sm`}
                // disabled={voucherKey <= -1 || !isValid ? "disabled" : ""}
                disabled={true}

                onClick={() => {
                  // checkAppliedPromo(voucher?.pos_redeem_sku);
                  // console.log(applied_voucher[voucherKey],"------------------------------k-kkK))))))))))))))))")
                  if (!isValid) {
                    return;
                  } else if (isVoucher_applied ) {
                    dispatch(removeItemVoucherDetail(voucherKey));
                    dispatch(emptyPromocodeDetail());

                                    
                  } else {
                    // dispatch(getApplyVoucherDetail(voucher, merchantId));
                    checkAppliedPromo(voucher?.pos_redeem_sku)
                  }
                }}
              >
                {`${isVoucher_applied ? "Applied" : "Apply"}`}
                {/* {"Apply"} */}
              {/* </button>
            )} */} 
          </div>
        </div>
      </div>
      <Collapsible open={open}>
        <div className="row">
          <div className="col-4 pr-0">
            <label> Promotion Code </label>
            <p>
              {" "}
              {voucher?.pos_redeem_sku
                ? voucher?.pos_redeem_sku
                : voucher?.description
                ? voucher?.description
                : voucher?.VoucherNo != ""
                ? voucher?.VoucherNo
                : "N/A"}{" "}
            </p>
          </div>
          <div className="col-4 pr-0">
            <label> Promotion Type </label>
            <p> {`${getVoucherType(true)} ${getVoucherName()}`}</p>
          </div>
          <div className="col-4 pr-0">
            <label> Amount </label>
            <p>
              {voucher?.pos_redeem_percentage ? "" : `${getVoucherType()}`}
              {getVoucherValue()}
              {voucher?.pos_redeem_percentage && "%"}
            </p>
          </div>
          <div className="col-4 pr-0">
            <label> Qualifying Items </label>
            <p> Order </p>
          </div>
        </div>
      </Collapsible>
    </div>
  );
}

export default PromotionDetails;

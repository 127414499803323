import { put, takeLatest, select, call } from "redux-saga/effects";
import * as actionTypes from "../actions/types";
import {
  GET_TABLE_DETAIL,
  TABLE_DETAIL_RECEIVED,
  LOADING_STATUS,
  FILTER_TAG_DETAIL_RECEIVED,
  ADD_FILTER_TAG,
  ADD_FILTER_TAG_RECEIVED,
  ADD_SEARCH_TAG_RECEIVED,
  ADD_SEARCH_TEXT,
  GET_SELECTED_ITEM_DETAIL,
  SELECTED_ITEM_DETAIL_RECEIVED,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  CHANGE_TABLE_ALREADY_INUSE_MODAL,
  SEND_RATING_INPUT,
  RATING_INPUT_RECEIVED,
  CHECK_ACTIVE_ORDER,
  RECENT_ORDER_ID_RECEIVED,
  CART_DETAIL_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  CHANGE_ORDER_TIP,
  CHANGE_SESSION_EXPIRED_PAGE,
  EMPTY_SERVER_DETAIL,
  GET_SERVER_DETAIL,
  SERVER_DETAIL_RECEIVED,
  GET_OPEN_ORDERS,
  COMBINED_TICKET_DATA,
} from "../actions/types";
import {
  changePaymentDetail,
  changePosCartDetail,
  changeSessionExpiredPage,
  clearCacheStatus,
  emptyPosCartDetail,
  emptyUserDetail,
  item86DetailRecieved,
  saveCartTemporary,
  saveServerOrderId,
  showItemAvailableModal,
  showNotificationWithTimeout,
} from "../actions";
import {
  checkArray,
  checkNull,
  getCartSubmittedItems,
  getDeviceId,
  getDummyData,
  getPosCartSubmittedItems,
  getSessionStorageOrDefault,
  pluckArray,
} from "../../helpers";

const base_url = process?.env?.REACT_APP_API_URL;
let myHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
});
const defaultErrorMsg = "Something went wrong! try again later.";

function* updateRating(action) {
  console.log("base_url", base_url);
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  myHeaders.token = token;
  const json = yield fetch(`${base_url}/qr_code_apis/qr_rating`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action?.payload ?? {}),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("updateRating failed : " + error.message));
  if (json?.status) {
    yield put({ type: RATING_INPUT_RECEIVED, payload: action.star });

    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : "success"}`,
        "success"
      )
    );
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `updateRating error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* checkActiveOrder(action) {
  console.log("base_url=================== checkActiveOrder", base_url);
  const cart_detail = yield select(
    (state) => state?.cartDetailReducer?.cart_detail
  );
  const recent_order_id = yield select(
    (state) => state?.cartDetailReducer?.recent_order_id
  );
  const server_order_id = yield select(
    (state) => state?.tableDetailReducer?.server_order_id

  );
  // const table_order_id = yield select(
  //   (state) => state?.tableDetailReducer?.server_order_id
  // );
  const is_payment_started = yield select(
    (state) => state?.paymentDetailReducer?.is_payment_started
  );
  const is_payment_cleared = yield select(
    (state) => state?.paymentDetailReducer?.is_payment_cleared
  );
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  let items = cart_detail.filter((p) => p.is_added == false);
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const deviceId = yield getDeviceId();
  const servicePeriodExpire = getSessionStorageOrDefault(
    "servicePeriodExpire",
    undefined
  );
  myHeaders.token = token;

  const orderPayload = {
    customer_id: action.customerId ?? "",
    merchant_id: action.merchantId ?? "",
    table_number: action.tableNumber ?? "",
    date: servicePeriodExpire ?? "",

  };
  if (checkNull(action.customerId, false)) {
    delete orderPayload.customer_id;
    orderPayload.order_id = server_order_id;
  }

  const json = yield fetch(`${base_url}/qr_code_apis/get_order_details`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(orderPayload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("checkActiveOrder failed : " + error.message)
    );
  if (json?.status) {

    if (json?.data?.open_orders !== null) {
      // const orders = getDummyData()
      const orders = json?.data?.open_orders;
      if (orders.length > 0) {
        yield put({
          type: COMBINED_TICKET_DATA,
          payload: orders,
        });
      }

      const table_detail = yield select(
        (state) => state?.tableDetailReducer?.table_detail
      );
      if (checkNull(recent_order_id, false)) {

        if (
          checkNull(orders[0]?.order_offline_id, false) === false ||
          (orders[0]?.order_offline_id &&
            recent_order_id != orders[0]?.order_offline_id &&
            !is_payment_cleared)
        ) {
          console.log("chdeck checkActiveOrder Sessionnnn Expiredddd");
          // yield put(changeSessionExpiredPage(true));
        }
      }
      console.log(orders)
      yield put({
        type: RECENT_ORDER_ID_RECEIVED,
        payload: orders[0]?.order_offline_id ?? "",
      });
      yield put({
        type: CHANGE_ORDER_TIP,
        payload: orders[0]?.tips ?? 0,
      });
      console.log("ordersssss===> getCartSubmittedItems ", orders);
      const orderItems = getCartSubmittedItems(orders, table_detail);
      console.log("ordersssss===>  inside  the  getCartSubmittedItems", orderItems);
      yield put(
        saveCartTemporary(orderItems)
      );
      // debugger;
      if (orderItems?.length > 0) {
        items = [...items, ...orderItems];
      }
      const posItems = getPosCartSubmittedItems(orders, table_detail); // TODO payment_type
      if (posItems?.length > 0) {
        yield put(
          changePosCartDetail({
            payment_type: orders?.payment_type ?? [],
            split_type_name: orders?.split_type_name ?? "",
            promotions: orders?.promotions ?? [],
            items: posItems,
            transaction_id: orders?.transaction_id ?? [],
            fiserv_order_id: orders?.fiservorderId ?? [],
            employee_id: orders?.employee_id ?? [],
          })
        );
        yield put(
          changePaymentDetail({
            paymentOpenOrders: [json?.data?.open_orders],
            paymentType: checkArray(orders?.payment_type) ? "split" : "",
            splitPaymentArr: orders?.payment_type ?? [],
            wayToSplit: checkArray(orders?.payment_type)
              ? orders?.payment_type.length
              : 2,
            splitType: checkNull(orders?.split_type_name, false)
              ? orders?.split_type_name == "Split Evenly"
                ? 1
                : orders?.split_type_name == "Split By Item"
                  ? 2
                  : 3
              : 0,
          })
        );
      } else {
        yield put(emptyPosCartDetail());
      }
    }
    yield put(
      item86DetailRecieved(
        json?.data?.["86_items"] && checkArray(json?.data?.["86_items"])
          ? json?.data?.["86_items"]
          : []
      )
    );
    // if (checkArray(json?.data?.["86_items"])) {
    //   const items_86 = json?.data?.["86_items"];
    //   yield put(item86DetailRecieved(items_86));
    //   // check already 86 items
    //   const items86Ids = pluckArray(items_86, "id");
    //   const already86ItemsName = [];
    //   const already86ItemsId = [];
    //   cart_detail
    //     .filter((p) => p.is_added == false)
    //     .map((cartItem) => {
    //       if (items86Ids.includes(cartItem.id)) {
    //         already86ItemsId.push(cartItem.id);
    //         already86ItemsName.push(cartItem.pos_name);
    //       }
    //     });
    //   if (checkArray(already86ItemsName)) {
    //     yield put(
    //       showItemAvailableModal(
    //         `${already86ItemsName.join(", ")} ${
    //           already86ItemsName.length > 1 ? "are" : "is"
    //         } no longer available, removed from your basket.`
    //       )
    //     );
    //   }
    //   if (checkArray(already86ItemsId)) {
    //     items = items.filter(
    //       (p) => p.is_added == true || !already86ItemsId.includes(p.id)
    //     );
    //   }
    //   // check already 86 items END
    // }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `checkActiveOrder error : ${json?.message ? json.message : defaultErrorMsg
      }`
    );
  }
  console.log("CART_DETAIL_RECEIVED ------------------------------------------------------ 1", items)
  yield put({ type: CART_DETAIL_RECEIVED, cart_detail: items });

  yield put({ type: LOADING_STATUS, status: false });
  return json;
}

function* postSeatedTable({ payload, callback }) {
  yield put(saveServerOrderId(payload.orderId));
  delete payload.orderId;
  const res = yield call(checkActiveOrder, payload);
  return callback(res);
}

function* getOpenOrders(action) {
  console.log("base_url", base_url);
  const cart_detail = yield select(
    (state) => state?.cartDetailReducer?.cart_detail
  );
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put(
    changePaymentDetail({
      bill_not_found: false,
      bill_not_found_msg: "",
    })
  );
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const deviceId = yield getDeviceId();
  myHeaders.token = token;
  const json = yield fetch(`${base_url}/qr_code_apis/get_payment_details`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify({
      deviceid: deviceId ?? "",
      payment_code: action.paymentId ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("checkActiveOrder failed : " + error.message)
    );
  if (json?.status) {
    if (checkArray(json?.data?.open_orders)) {
      yield put(
        changePaymentDetail({
          paymentOpenOrders: json?.data?.open_orders ?? [],
        })
      );
    } else {
      yield put(
        changePaymentDetail({
          bill_not_found: true,
          bill_not_found_msg: "Sorry, unable to find any open order.",
        })
      );
    }
    sessionStorage.setItem("isTableDataLoaded", JSON.stringify(true));
    sessionStorage.setItem(
      "servicePeriodExpire",
      JSON.stringify(json?.data?.table_details?.service_period?.date)
    );
    yield put({
      type: TABLE_DETAIL_RECEIVED,
      json: json?.data?.table_details ?? null,
    });
  } else {
    yield put(
      changePaymentDetail({
        bill_not_found: true,
        bill_not_found_msg: `${json?.message ? json.message : defaultErrorMsg}`,
      })
    );
    // yield put(
    //   showNotificationWithTimeout(
    //     `${json?.message ? json.message : defaultErrorMsg}`,
    //     "error"
    //   )
    // );
    console.log(
      `checkActiveOrder error : ${json?.message ? json.message : defaultErrorMsg
      }`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* fetchTableDetail(action) {
  // localStorage.clear();
  yield put({ type: LOADING_STATUS, status: true });
  if (action?.resetAuth == true) {
    yield put(emptyUserDetail());
  }
  const server_user_detail = yield select(
    (state) => state?.authDetailReducer?.server_user_detail
  );
  const pos_cart_detail = yield select(
    (state) => state?.cartDetailReducer?.pos_cart_detail
  );
  const token = yield select(
    (state) => state?.authDetailReducer?.authToken
  );
  const deviceId = yield getDeviceId();
  const json = yield fetch(
    `${base_url}/qr_code_apis/get_table_details/${action.id}`,
    {
      method: "POST",
      mode: "cors",
      headers: myHeaders,
      body: JSON.stringify({
        device_id: deviceId ?? "",
        token: token ?? ""
      }),
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("fetchTableDetail failed : " + error.message)
    );
  const tagsJson = yield fetch(`${base_url}/qr_code_apis/get_tags`, {
    mode: "cors",
    headers: myHeaders,
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("get_tagsDetail failed : " + error.message));
  if (json?.status && json?.data) {
    sessionStorage.setItem("isTableDataLoaded", JSON.stringify(true));
    sessionStorage.setItem(
      "servicePeriodExpire",
      JSON.stringify(json?.data?.service_period?.date)
    );
    yield put({ type: TABLE_DETAIL_RECEIVED, json: json?.data });
    if (json?.data?.occupied == 1) {
      if (checkNull(server_user_detail?.id, false)) {
        if (
          checkNull(pos_cart_detail?.employee_id, false) &&
          parseInt(pos_cart_detail?.employee_id) > 0 &&
          pos_cart_detail?.employee_id != server_user_detail?.id
        )
          yield put({ type: CHANGE_TABLE_ALREADY_INUSE_MODAL, payload: true });
      } else {
        yield put({ type: CHANGE_TABLE_ALREADY_INUSE_MODAL, payload: true });
      }
    }
    if (json?.data?.Quota) {
      yield put(
        item86DetailRecieved(
          json?.data?.Quota && checkArray(json?.data?.Quota)
            ? json?.data?.Quota
            : []
        )
      );
    }
    if (tagsJson?.status && tagsJson?.data)
      yield put({ type: FILTER_TAG_DETAIL_RECEIVED, json: tagsJson?.data });
  } else {
    if (json?.message !== undefined) {
      yield put({ type: CHANGE_TABLE_NOT_FOUND_MODAL, payload: true });
    }
    console.log(
      `fetchTableDetail error : ${json?.message ? json.message : defaultErrorMsg
      }`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* fetchTableDetailByfilter(action) {
  yield put({ type: ADD_FILTER_TAG_RECEIVED, payload: action.ids });
  yield put({ type: ADD_SEARCH_TAG_RECEIVED, input: action.input });
  yield put({ type: ADD_SEARCH_TEXT, input: action.input });
  // NOT IN USE
  // yield put({ type: LOADING_STATUS, status: true });
  // const json = yield fetch(
  //   `${base_url}/qr_code_apis/get_table_details/${action.table_id}?tag_ids=${action.ids}&search=${action.input}`,
  //   {
  //     mode: "cors",
  //     headers: myHeaders,
  //   }
  // )
  //   .then((response) => response.json())
  //   .then((json) => json)
  //   .catch((error) =>
  //     console.log("fetchTableDetail failed : " + error.message)
  //   );
  // yield put({ type: LOADING_STATUS, status: false });
  // if (json?.status && json?.data) {
  //   yield put({ type: TABLE_DETAIL_RECEIVED, json: json?.data });
  //   yield put({ type: ADD_FILTER_TAG_RECEIVED, payload: action.ids });
  //   yield put({ type: ADD_SEARCH_TAG_RECEIVED, input: action.input });
  // } else {
  //   console.log(
  //     `fetchTableDetail error : ${
  //       json?.message ? json.message : defaultErrorMsg
  //     }`
  //   );
  // }
}

function* fetchSelectedItemDetail(action) {
  yield put({ type: SELECTED_ITEM_DETAIL_RECEIVED, payload: action.id });
}

function* getServerDetail(action) {
  console.log("base_url getServerDetail ", base_url);
  yield put({ type: LOADING_STATUS, status: true });
  // yield put({ type: actionTypes.USER_INITIAL_STATE });
  // yield put({ type: actionTypes.TABLE_INITIAL_STATE });
  yield put({ type: actionTypes.CART_INITIAL_STATE });
  yield put({ type: actionTypes.PAYMENT_INITIAL_STATE });
  const json = yield fetch(`${base_url}/qr_code_apis/server_login`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action?.payload ?? {}),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("updateRating failed : " + error.message));


  if (json?.status) {
    yield put({ type: SERVER_DETAIL_RECEIVED, payload: json.data });
    if (json.data?.PosRoom && checkArray(json.data?.PosRoom)) {


      yield put({ type: actionTypes.BOOL_QR_ORDER, payload: true });

    }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* checkActiveOrder_for_QR_Ordering(action) {
  console.log("checkActiveOrder_for_QR_Ordering", base_url);

  yield put({ type: LOADING_STATUS, status: true });
  const deviceId = yield getDeviceId();
  // myHeaders.token = token;
  const orderPayload = {
    customer_id: action.customerId ?? "",
    merchant_id: action.merchantId ?? "",
    table_number: action.tableNumber ?? "",
  };

  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  myHeaders.token = token;

  const json = yield fetch(`${base_url}/qr_code_apis/get_order_details`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(orderPayload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("checkActiveOrder failed : " + error.message)
    );
  if (json?.status) {
    if (json?.data?.open_orders !== null) {
      const orders = json?.data?.open_orders;
      yield put({ type: actionTypes.RECIVED_OPEN_ORDER_DATA, orders });

    } else {
      yield put(
        showNotificationWithTimeout(
          `${json?.message ? json.message : defaultErrorMsg}`,
          "error"
        )
      );
      console.log(
        `checkActiveOrder error : ${json?.message ? json.message : defaultErrorMsg
        }`
      );
    }
  }

  yield put({ type: LOADING_STATUS, status: false });
  return json;
}


export default function* tableDetailActionWatcher() {
  yield takeLatest(GET_TABLE_DETAIL, fetchTableDetail);
  yield takeLatest(ADD_FILTER_TAG, fetchTableDetailByfilter);
  yield takeLatest(GET_SELECTED_ITEM_DETAIL, fetchSelectedItemDetail);
  yield takeLatest(SEND_RATING_INPUT, updateRating);
  yield takeLatest(CHECK_ACTIVE_ORDER, checkActiveOrder);
  yield takeLatest(GET_SERVER_DETAIL, getServerDetail);
  yield takeLatest(GET_OPEN_ORDERS, getOpenOrders);
  yield takeLatest(actionTypes.GET_SERVER_ORDER_ID, postSeatedTable);
  yield takeLatest(actionTypes.GET_OPER_ORDER_DATA, checkActiveOrder_for_QR_Ordering);

}

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";

import {} from "../../Assets";
import PaymentInfo from "./PaymentInfo";
import {
  capitalizeFirstLetter,
  checkArray,
  checkNull,
  parseRound,
} from "../../helpers";
import { APPLY_VOUCHER_DETAIL_RECEIVED } from "../../Redux/actions/types";
import NumberFormat from "react-number-format";
import PaymentSuccess from "./PaymentSuccess";
import { changePaymentDetail } from "../../Redux/actions";

function PaymentConfirm({ setShowPayConfirmPage, style }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer.user_detail
  );
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  const applied_voucher = useSelector(
    (state) => state.cartDetailReducer?.applied_voucher
  );
  const applied_promocode = useSelector(
    (state) => state.cartDetailReducer?.applied_promocode
  );
  const { splitType, splitPaymentArr } = useSelector(
    (state) => state.paymentDetailReducer
  );
  const total_tip = useSelector((state) => state.cartDetailReducer?.total_tip);

  const splitPayIndex =
    splitPaymentArr.findIndex((p) => p?.isPaid == 0 || p?.isPaid == false) > -1
      ? splitPaymentArr.findIndex((p) => p?.isPaid == 0 || p?.isPaid == false)
      : null;

  let defaultDiscountTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount
  );
  const [totalServiceCharge, setTotalServiceCharge] =
    useState(defaultServiceCharge);
  const [totalSaleTax, setTotalSaleTax] = useState(defaultTotalSaleTax);
  const [totalTip, setTotalTip] = useState(`${total_tip}`);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);

  const [slidePaySuccessPage, setSlidePaySuccessPage] = useState(false);
  const [showPaySuccessPage, setShowPaySuccessPage] = useState(false);
  const [successPageState, setSuccessPageState] = useState(null);

  const togglePaySuccessPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showPaySuccessPage) {
        setSlidePaySuccessPage(false);
        setTimeout(() => {
          setShowPaySuccessPage(!showPaySuccessPage);
        }, 500);
      } else {
        setShowPaySuccessPage(!showPaySuccessPage);
        setTimeout(() => {
          setSlidePaySuccessPage(true);
        }, 100);
      }
    },
    [showPaySuccessPage, slidePaySuccessPage, successPageState]
  );

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  useEffect(() => {
    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;

    //applied_voucher
    let defaultTotalDiscount = 0;
    if (applied_voucher && applied_voucher.length > 0) {
      const newApplyVoucher = applied_voucher.map((applyVoucher) => {
        if (applyVoucher?.Type == "Cash") {
          const discountAmt = parseFloat(applyVoucher?.TypeValue ?? 0);
          applyVoucher.discountAmt = discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        } else if (applyVoucher?.Type == "Discount") {
          const discountAmt =
            (parseFloat(applyVoucher?.TypeValue ?? 0) *
              defaultDiscountTotalCartAmount) /
            100;
          applyVoucher.discountAmt = discountAmt;
          defaultDiscountTotalCartAmount =
            defaultDiscountTotalCartAmount - discountAmt;
          defaultTotalDiscount = defaultTotalDiscount + discountAmt;
        }
        return applyVoucher;
      });
      dispatch({
        type: APPLY_VOUCHER_DETAIL_RECEIVED,
        payload: newApplyVoucher,
      });
    }
    if (applied_promocode && applied_promocode !== null) {
      defaultTotalDiscount = defaultTotalDiscount + getPromotionTotalDis();
    }
    setTotalDiscount(defaultTotalDiscount);
    // applied_voucher END

    setTotalSaleTax(newTotalSaleTax);
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
  }, [cart_detail]);

  const calculateIsToolBarStatus = () => {
    let res = false;
    let maxHeight = window.outerHeight - window.innerHeight;
    if (maxHeight > 120) {
      res = true;
    }
    return res;
  };

  const [totalHeight, setTotalHeight] = useState(window.innerHeight);
  const totalHeightRef = useRef(totalHeight);
  totalHeightRef.current = totalHeight;
  const [isToolBarHide, setIsToolBarHide] = useState(
    calculateIsToolBarStatus()
  );

  useEffect(() => {
    setIsToolBarHide(calculateIsToolBarStatus());
  }, [totalHeight]);

  useEffect(() => {
    window.addEventListener("resize", function (event) {
      setTotalHeight(window.innerHeight);
    });
    dispatch(
      changePaymentDetail({
        is_payment_started: true,
      })
    );

    return () => {
      window.removeEventListener("resize", (e) => {});
      changePaymentDetail({ is_payment_started: false });
    };
  }, []);

  const getPromotionTotalDis = () => {
    let res = getBasketTotalAmount(true);
    let promotionDiscount = 0;
    if (applied_promocode && applied_promocode !== null) {
      if (applied_promocode.type == "Amount") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = res - promotionDiscount;
      } else if (applied_promocode.type == "Percentage") {
        promotionDiscount = (res * parseFloat(applied_promocode.amount)) / 100;
        res = res - promotionDiscount;
      } else if (applied_promocode.type == "Re-Price") {
        promotionDiscount = parseFloat(applied_promocode.amount);
        res = promotionDiscount;
      }
    }
    return res;
  };

  function getBasketTotalAmount(onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalTipCh = parseFloat(totalTip.toString().replace("$", ""));
      const totalDisCh = parseFloat(onlyTotal ? 0 : totalDiscount);
      res =
        totalCartAmount +
        totalServiceCharge +
        totalTipCh +
        totalSaleTax -
        totalDisCh;
    }
    return (
      <>
        {`${table_detail?.currency ?? ""}`}
        {parseRound(res)}
      </>
    );
  }
  function getCartTotalAmount(withoutTip = false, onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalTipCh = withoutTip
        ? 0
        : parseFloat(totalTip.toString().replace("$", ""));
      const totalDisCh = parseFloat(onlyTotal ? 0 : totalDiscount);
      res =
        totalCartAmount +
        totalServiceCharge +
        totalTipCh +
        totalSaleTax -
        totalDisCh;
    }
    return parseRound(res, 2, true);
  }

  const getPaymentSoFarList = () => {
    let res = <li className="mt-3">No payments made yet.</li>;
    if (
      checkArray(splitPaymentArr) &&
      checkArray(
        splitPaymentArr.filter((p) => p.isPaid === 1 || p.isPaid === true)
      )
    ) {
      res = (
        <>
          <li>
            <strong className="bold-txt">PAYMENTS ON ORDER SO FAR</strong>
          </li>
          {splitPaymentArr
            .filter((p) => p.isPaid === 1 || p.isPaid === true)
            .map((prevPay, prevInx) => {
              return (
                <li>
                  <span>
                    {prevInx + 1}{" "}
                    {capitalizeFirstLetter(prevPay.cardHolderName)} -{" "}
                    <span className="txt-blue">Paid</span>
                  </span>
                  <strong> ${parseRound(prevPay?.splitPrice ?? 0)} </strong>
                </li>
              );
            })}
        </>
      );
    }
    return res;
  };

  function getCartDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <>
          <div
            className={`wrapper cart_wrapper cart_sidebar order-summary payment-confimation ${
              isToolBarHide ? "" : "toolbar-vh"
            }`}
            onScroll={scrolledElement}
            {...handlers}
            style={style}
          >
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 130 : 0, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <Header
                  backTitle={"Back"}
                  showIcons={false}
                  backPath={setShowPayConfirmPage}
                  hideHeader={!setShowPayConfirmPage}
                  style={{ height: `${x}px` }}
                  rtComponent={
                    <>
                      <div className="hotel_name">
                        <div className="circle-img">
                          <figure className="">
                            <ImageTag
                              src={table_detail?.Merchant?.logo}
                              alt=""
                            />
                          </figure>
                        </div>
                        <div className="naming-sec text-center">
                          <h4> {table_detail?.Merchant?.account_name} </h4>
                          <p>
                            Table #{table_detail?.PosTableMaster?.table_number}{" "}
                            Basket
                          </p>
                        </div>
                      </div>
                    </>
                  }
                />
              )}
            </Motion>
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 0 : 60, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <Header
                  backTitle={"Back"}
                  showIcons={false}
                  backPath={setShowPayConfirmPage}
                  hideHeader={!setShowPayConfirmPage}
                  style={{
                    height: `${x}px`,
                    position: "fixed",
                    top: "0px",
                    width: "100%",
                    zIndex: 11,
                    right: `${style.right}`,
                    backgroundColor: "#f5f6f7",
                  }}
                  rtComponent={<></>}
                />
              )}
            </Motion>
            <section
              className={`middle-container ${hideHeader ? "scrollUpTwo" : ""}`}
            >
              <ul class="total-bill mx-n3 px-4 py-2 mb-0">
                <li>
                  <span> Bill Total </span>
                  <strong>
                    <small>$</small>
                    {getCartTotalAmount(true)}
                  </strong>
                </li>
                {splitType !== 3 && (
                  <li>
                    <span> Your Payment </span>
                    <strong>
                      <small>$</small>
                      {parseRound(splitPaymentArr?.[splitPayIndex]?.splitPrice)}
                    </strong>
                  </li>
                )}

                {getPaymentSoFarList()}
              </ul>
              <PaymentInfo
                cartTotalAmount={getCartTotalAmount()}
                cartAmount={splitPaymentArr?.[splitPayIndex]?.splitPrice}
                splitPayIndex={splitPayIndex}
                totalTip={parseFloat(totalTip.toString().replace("$", ""))}
                togglePaySuccessPopUp={togglePaySuccessPopUp}
                setSuccessPageState={setSuccessPageState}
              />
            </section>
          </div>
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePaySuccessPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPaySuccessPage ? (
                <PaymentSuccess
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPaySuccessPage={togglePaySuccessPopUp}
                  successPageState={successPageState}
                />
              ) : null;
            }}
          </Motion>
        </>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default PaymentConfirm;

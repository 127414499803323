import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import DraggableItem from "../Components/DraggableItem";
import { serverDetailsLogo } from "../../Assets";
import Header from "../Components/Header";
import CartHeader from "../Components/CartHeader";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import AccountPopup from "../Components/AccountPopup";
import {
  removeItemCartDetail,
  getSelectedItemDetail,
  dismissItemAvailableModal,
  quantityModalClose,
  quantityCheckedDetails,
} from "../../Redux/actions";
import {
  checkArray,
  checkItemAvailable,
  checkNull,
  checkSubCatAvailable,
  countDecimals,
  getCartNewItemCount,
  itemsInBasketCase,
  parseRound,
  parseTwoDigitNumber,
  sortCartItems,
} from "../../helpers";
import { TOGGLE_CART_PAGE } from "../../Redux/actions/types";
import PromocodePopup from "../Components/PromocodePopup";
import Modal from "../Components/Modal";
import TipPopup from "../Components/TipPopup";
import { Terms } from "../Components/Terms";
import Payment from "./Payment";
import PaymentConfirm from "./PaymentConfirm";

function Cart(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const {
    show_item_unavailable_modal,
    show_item_unavailable_text,
    server_detail,
  } = useSelector((state) => state.tableDetailReducer);
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  // console.log("cart_detail",cart_detail)
  const pos_cart_detail = useSelector(
    (state) => state.cartDetailReducer?.pos_cart_detail
  );
  // console.log("pos_cart_detail",pos_cart_detail)
  const applied_promocode = useSelector(
    (state) => state.cartDetailReducer?.applied_promocode
  );
  const applied_voucher = useSelector(
    (state) => state.cartDetailReducer?.applied_voucher
  );
  const show_cart_popup = useSelector(
    (state) => state.cartDetailReducer?.show_cart_popup
  );
  // console.log("cart_detail", JSON.stringify(cart_detail));
  const recent_order_detail = useSelector(
    (state) => state.cartDetailReducer?.recent_order_detail
  );
  const recent_order_id = useSelector(
    (state) => state.cartDetailReducer?.recent_order_id
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer?.user_detail
  );
  const showPostTaxPrice =
    table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
      ? true
      : false;

  const [accountTabType, setAccountTabType] = useState(0);
  const [isCartChanged, setIsCartChanged] = useState(false);
  const [showSubmittedItem, setShowSubmittedItem] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const loyaltyEnabled = table_detail?.Merchant?.loyalty_enabled;
  const showPayPopUp =
    table_detail?.QrOrdering?.payment_accepted == "yes" &&
      table_detail?.QrOrdering?.payment_required_upfront != "yes"
      ? true
      : false;
  const isUpFrontPayment =
    table_detail?.QrOrdering?.payment_required_upfront == "yes" &&
      !server_detail
      ? true
      : false;
  const {
    slidePaymentPage,
    showPaymentPage,
    slidePayConfirmPage,
    showPayConfirmPage,
    cartBgWhite,
    togglePaymentPopUp,
    togglePayConfirmPopUp,
    totalCartAmount,
    totalServiceCharge,
    totalSaleTax,
    totalDiscount,
    promoDiscount,
    totalTip,
    getCartBasketTotalAmount,
    setShowAccountPopUp,
    showAccountPopUp,
    showPromocodePopup,
    setShowPromocodePopup,
    submitOrderHandler,
    tipChangeHandler,
    showTipPopup,
    setShowTipPopup,
    totalItemLevelDiscount,
  } = props;
  // const [slidePaymentPage, setSlidePaymentPage] = useState(false);
  // const [showPaymentPage, setShowPaymentPage] = useState(false);

  // const [slidePayConfirmPage, setSlidePayConfirmPage] = useState(false);
  // const [showPayConfirmPage, setShowPayConfirmPage] = useState(false);
  // const [cartBgWhite, setCartBgWhite] = useState(false);

  // const togglePaymentPopUp = useCallback(
  //   (event) => {
  //     // event.preventDefault();
  //     if (showPaymentPage) {
  //       setSlidePaymentPage(false);
  //       setCartBgWhite(false);
  //       setTimeout(() => {
  //         setShowPaymentPage(!showPaymentPage);
  //       }, 500);
  //     } else {
  //       setShowPaymentPage(!showPaymentPage);
  //       setTimeout(() => {
  //         setSlidePaymentPage(true);
  //       }, 100);
  //       setTimeout(() => {
  //         setCartBgWhite(true);
  //       }, 500);
  //     }
  //   },
  //   [showPaymentPage, slidePaymentPage]
  // );

  // const togglePayConfirmPopUp = useCallback(
  //   (event) => {
  //     // event.preventDefault();
  //     if (showPayConfirmPage) {
  //       setSlidePayConfirmPage(false);
  //       setCartBgWhite(false);
  //       setTimeout(() => {
  //         setShowPayConfirmPage(!showPayConfirmPage);
  //       }, 500);
  //     } else {
  //       setShowPayConfirmPage(!showPayConfirmPage);
  //       setTimeout(() => {
  //         setSlidePayConfirmPage(true);
  //       }, 100);
  //       setTimeout(() => {
  //         setCartBgWhite(true);
  //       }, 500);
  //     }
  //   },
  //   [showPayConfirmPage, slidePayConfirmPage]
  // );

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  function removeItemHandler(index) {
    const itemIndx = cart_detail.findIndex((p) => p.offline_id == index);
    if (itemIndx > -1) {
      dispatch(removeItemCartDetail(itemIndx));
    }
    setIsCartChanged(true);
  }
  console.log('cart_detail', cart_detail)
  function getCartItems() {
    if (cart_detail && cart_detail.length > 0) {
      return cart_detail
        .filter(
          (p) =>
            showSubmittedItem
              ? p.is_added == true || p.is_fired == true || (p.menu_item_type == "bundled" && p.is_fired == true && p?.BundledMenuItem?.every((item) => item.bundle_hide_fixed_items == "yes")) || (p.menu_item_type == "bundled" && p?.BundledMenuItem?.length > 0 && p?.BundledMenuItem?.every((item) => item.is_fired == true) && p?.BundledMenuItem?.some((item) => item.bundle_hide_fixed_items !== "yes"))
              : itemsInBasketCase(p)
          // showSubmittedItem ? p.is_added == true && p.is_fired == true   : p.is_added == false ||p.is_fired == false
          // showSubmittedItem ? p.is_added == true && p.is_fired == true : p.is_added == false && p.is_fired==false
        )
        .map(function (item, itemIndex) {
          return (
            <DraggableItem
              item={item}
              itemIndex={item.offline_id}
              defaultimage={table_detail?.Merchant?.logo}
              removeItemHandler={removeItemHandler}
              key={item.offline_id}
            />
          );
        });
    }
    return null;
  }

  function getQRCartItems() {
    if (cart_detail && cart_detail.length > 0) {
      return sortCartItems(cart_detail)
        .filter((p) =>
          showSubmittedItem ? p.is_added == true : p.is_added == false
        )
        .map(function (item, itemIndex) {
          return (
            <DraggableItem
              item={item}
              itemIndex={item.offline_id}
              defaultimage={table_detail?.Merchant?.logo}
              removeItemHandler={removeItemHandler}
              key={item.offline_id}
            />
          );
        });
    }
    return null;
  }

  function getPopularItems() {
    let res = [];
    if (table_detail?.SubCategory?.length > 0) {
      table_detail?.SubCategory.filter(
        (subCat) =>
          checkSubCatAvailable(subCat, table_detail) &&
          subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
      ).map(function (subCat, index) {
        if (subCat?.MenuItem) {
          subCat?.MenuItem.filter(
            (item) =>
              checkItemAvailable(
                item,
                table_detail?.Merchant?.timezone_offset ?? "00:00",
                table_detail
              ) && item.is_popular == 1
          ).map(function (item, itemKey) {
            res.push(item);
          });
        }
      });
    }
    if (res.length > 0) {
      return res.slice(0, 4).map((item) => {
        return (
          <div
            className="col-6"
            onClick={() => {
              dispatch(getSelectedItemDetail(item));
              dispatch({ type: TOGGLE_CART_PAGE, payload: true });
              if (item?.menu_item_type == "bundled")
                history.push("/bundle-add-item");
              else history.push("/add-item");
            }}
          >
            <figure className="menu-img mx-auto mb-3">
              <ImageTag
                src={item?.image}
                defaultimage={table_detail?.Merchant?.logo}
                alt=""
              />
            </figure>
            <h5 className="sm-h5"> {item?.menu_item_name ?? ""} </h5>
            <p>
              {" "}
              @
              {showPostTaxPrice
                ? item?.dinein_price?.DineInPostTax !== undefined
                  ? parseRound(item?.dinein_price?.DineInPostTax)
                  : "0.00"
                : item?.dinein_price?.DineIn !== undefined
                  ? parseRound(item?.dinein_price?.DineIn)
                  : "0.00"}
            </p>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  function getBasketTotalQuantity() {
    let res = getCartNewItemCount(cart_detail);
    return (
      <div
        className="items-count"
        style={{ backgroundColor: table_detail?.QrOrdering?.brand_main_color }}
      >
        {" "}
        {res}{" "}
      </div>
    );
  }


  function getCartDetail() {
    console.log(
      totalCartAmount,
      totalDiscount,
      promoDiscount,
      totalItemLevelDiscount,
      "-------1212121112212--------------------------------Testing---------------------------------------121212121212121212121212121212121212122------"
    );
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <>
          <div
            className={`wrapper cart_wrapper custom_pb cart_sidebar hasAnotherSlide${cartBgWhite ? " white-bg" : ""
              }`}
            onScroll={scrolledElement}
            {...handlers}
            style={props.style}
          >
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(
                  hideHeader === false
                    ? cart_detail.findIndex((p) => p.is_added == true) > -1
                      ? 160
                      : 160
                    : 0,
                  {
                    precision: 1,
                    stiffness: 300,
                    damping: 44,
                  }
                ),
              }}
            >
              {({ x }) => (
                <CartHeader
                  backTitle={"Back to Menu"}
                  onTabProfile={() => {
                    if (user_detail) {
                      setShowAccountPopUp(1);
                    } else {
                      history.push("/login");
                    }
                  }}
                  backPath={() => {
                    props.setShowCartPage();
                    // setHideHeader(true);
                  }}
                  style={{
                    height: `${x}px`,
                  }}
                  rtComponent={
                    <>
                      <div className="hotel_name">
                        {!server_detail ? (
                          <div className="hotel_name_icon">
                            <div className="circle-img">
                              <figure className="">
                                <ImageTag
                                  src={table_detail?.Merchant?.logo}
                                  alt=""
                                />
                              </figure>
                            </div>

                            <div className="naming-sec text-center">
                              <h4> {table_detail?.Merchant?.account_name} </h4>
                              <p>
                                {" "}
                                Table{" "}
                                {
                                  table_detail?.PosTableMaster?.table_number
                                }{" "}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <ImageTag
                            src={serverDetailsLogo}
                            alt=""
                            className="px-0 col-4"
                          />
                        )}
                        {cart_detail.findIndex((p) => p.is_added == true || (p.menu_item_type == "bundled" &&  p?.BundledMenuItem?.every((item) => item.is_fired == true))) >
                          -1 && (
                            <div className="col-12 pt-5 py-3 px-0">
                              <h5
                                className={`green-title ${cart_detail.findIndex(
                                  (p) => p.is_added == true
                                ) > -1
                                  ? ""
                                  : "grey"
                                  }`}
                                onClick={() => {
                                  if (
                                    cart_detail.findIndex(
                                      (p) => p.is_added == true || (p.menu_item_type == "bundled" &&  p?.BundledMenuItem?.every((item) => item.is_fired == true))
                                    ) > -1
                                  )
                                    setShowSubmittedItem(!showSubmittedItem);
                                }}
                                style={{
                                  color:
                                    table_detail?.QrOrdering
                                      ?.brand_highlight_color,
                                }}
                              >
                                {showSubmittedItem
                                  ? "View Items In Basket"
                                  : "View Previously Submitted Items"}
                              </h5>
                            </div>
                          )}
                      </div>
                    </>
                  }
                />
              )}
            </Motion>
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 0 : 60, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <CartHeader
                  backTitle={"Back to Menu"}
                  onTabProfile={() => {
                    if (user_detail) {
                      setShowAccountPopUp(1);
                    } else {
                      history.push("/login");
                    }
                  }}
                  backPath={() => {
                    props.setShowCartPage();
                    // setHideHeader(true);
                  }}
                  style={{
                    height: `${x}px`,
                    position: "fixed",
                    top: "0px",
                    width: "100%",
                    zIndex: 9,
                    right: `${props.style.right}`,
                    backgroundColor: "#f5f6f7",
                  }}
                  rtComponent={<></>}
                />
              )}
            </Motion>
            {/* 
                    <header>
                        <div className="container">
                            <div className="row mx-0">
                                <div className="col-8">
                                    <a href="index.html" className="back"> Back to Order </a>
                                </div>
                                <div className="col-4 notify">
                                    <a href="#_" className="notification">
                                        <span className="counts"> 10 </span>
                                    </a>
                                    <a href="#_" className="profile"> </a>
                                </div>
                            </div>
        
        
        
                            <div className="row hotel_name">
                                <div className="circle-img col-2">
                                    <figure className=""><img src={userImgIcon} alt="" /></figure>
                                </div>
                                <div className="naming-sec col-6 px-0 text-center">
                                    <h4> Harry's Boat Quay </h4>
                                    <p> Table #17 Basket </p>
                                </div>
                            </div>
                            <div className="col-12 py-3">
                                <h5 className="green-title"> View Submitted Items </h5>
                            </div>
                        </div>
                <div className="items-count"> 2 </div>
                    </header> */}

            <div className={`outerScroll ${hideHeader ? "scrollup" : ""}`}>
              <section className="middle-sec cart-sec-ms new_items_page recommended">
                <h3 class="title">
                  {" "}
                  {showSubmittedItem
                    ? "Previously Submitted Items"
                    : "Items in Basket"}{" "}
                </h3>

                {server_detail ? getCartItems() : []}
              </section>

              {table_detail?.SubCategory &&
                table_detail.SubCategory.findIndex(
                  (subCat) =>
                    subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
                ) > -1 && (
                  <section className="recommended">
                    <h3 className="title mt-3"> You Might Also Like </h3>
                    <div className="row also-like-sec">{getPopularItems()}</div>
                  </section>
                )}

              <section className="billing-details mb-5">
                <ul className="billing-list">
                  <li>
                    {" "}
                    <span> Sub Total </span>{" "}
                    <strong>
                      {" "}
                      <small>{`${table_detail?.currency ?? ""}`}</small>
                      {/* {`${parseFloat(totalCartAmount).toFixed(3).slice(0, -1)}`} */}
                      {parseRound(
                        totalCartAmount,
                        // totalDiscount +
                        // promoDiscount +
                        // totalItemLevelDiscount,
                        2,
                        false
                      )}
                    </strong>
                  </li>
                  <li>
                    {" "}
                    <span> Discounts </span>{" "}
                    <strong>
                      (<small>{`${table_detail?.currency ?? ""}`}</small>
                      {/* {`${parseFloat(totalDiscount).toFixed(3).slice(0, -1)}`}) */}
                      {`${parseRound(
                        totalDiscount + promoDiscount + totalItemLevelDiscount
                      )}`}
                      )
                    </strong>
                  </li>
                  <li>
                    {" "}
                    <span> Service Charges </span>{" "}
                    <strong>
                      {" "}
                      <small>{`${table_detail?.currency ?? ""}`}</small>
                      {/* {parseFloat(totalServiceCharge).toFixed(3).slice(0, -1)} */}
                      {parseRound(totalServiceCharge)}
                    </strong>
                  </li>

                  <li>
                    {" "}
                    <span> Tax </span>{" "}
                    <strong>
                      {" "}
                      <small>{`${table_detail?.currency ?? ""}`}</small>
                      {/* {parseFloat(totalSaleTax).toFixed(3).slice(0, -1)} */}
                      {parseRound(totalSaleTax)}
                    </strong>
                  </li>
                  <li>
                    {" "}
                    <span> Gratuity </span>{" "}
                    <input
                      type="text"
                      className="type-text"
                      value={`$${parseTwoDigitNumber(totalTip)}`}
                      onClick={() => {
                        setShowTipPopup(1);
                      }}
                      readOnly={true}
                    />
                  </li>
                  <li className="total">
                    {" "}
                    <span> Total </span>{" "}
                    <strong> {getCartBasketTotalAmount()}</strong>
                  </li>
                </ul>
                <div className="row mx-0 justify-content-between px-4">
                  <div
                    onClick={() => {
                      setShowPromocodePopup(1);
                    }}
                    className="link"
                    style={{
                      color: table_detail?.QrOrdering?.brand_highlight_color,
                    }}
                  >
                    {" "}
                    Use Promo Code{" "}
                  </div>
                  {loyaltyEnabled && (
                    <div
                      onClick={() => {
                        if (user_detail) {
                          setAccountTabType(1);
                          setShowAccountPopUp(1);
                        } else {
                          history.push("/login");
                        }
                      }}
                      className="link"
                      style={{
                        color: table_detail?.QrOrdering?.brand_highlight_color,
                      }}
                    >
                      {" "}
                      Use Voucher{" "}
                    </div>
                  )}
                  {/* {showPayPopUp && (
                    <div class="pay-bill">
                      <h3 class="title"> Pay your bill </h3>
                      <p>
                        {" "}
                        All finished and ready to go? You can view, split, pay
                        your bill and add a tip by clicking below.{" "}
                      </p>
                      <a
                        onClick={() => orderPlaceHandler(false, totalTip, true)}
                        className="btn pink-btn"
                      >
                        {" "}
                        <strong> Continue to Pay </strong>{" "}
                      </a>
                    </div>
                  )} */}
                </div>
              </section>
            </div>

            <section
              className={`bottom-sec ${cart_detail?.filter(
                (p) => p?.is_added == false || p.is_fired == false
              )?.length <= 0
                ? "disabled"
                : ""
                }`}
              style={props.style}
            >
              <div
                // onClick={() => submitOrderHandler()}
                onClick={
                  () =>
                    isUpFrontPayment
                      ? dispatch(quantityCheckedDetails())
                      : getCartNewItemCount(cart_detail) > 0
                        ? dispatch(quantityCheckedDetails())
                        : //  setShowAccountPopUp(1)
                        props.setShowCartPage()
                  // () => dispatch(quantityCheckedDetails())
                }
                className="green-sec py-3"
                style={{
                  backgroundColor: table_detail?.QrOrdering?.brand_main_color,
                }}
              >
                {/* {!isUpFrontPayment && (
                  <div className="col-2">{getBasketTotalQuantity()}</div>
                )} */}

                {isUpFrontPayment ? (
                  <></>
                ) : getCartNewItemCount(cart_detail) > 0 ? (
                  <div className="col-2">{getBasketTotalQuantity()}</div>
                ) : (
                  <></>
                )}

                <div
                  className={`${
                    // !isUpFrontPayment ? "col-8" : "col"
                    isUpFrontPayment
                      ? "col-12"
                      : getCartNewItemCount(cart_detail) > 0
                        ? "col-8"
                        : "col-12"
                    } text-center`}
                >
                  <strong>
                    {" "}
                    {/* {isUpFrontPayment ? "Go To Pay" : "Submit New Items"}{" "} */}
                    {isUpFrontPayment
                      ? "Go To Pay"
                      : getCartNewItemCount(cart_detail) > 0
                        ? "Submit New Items"
                        : "Go Back And Add New Items"}
                  </strong>
                </div>
                {!isUpFrontPayment && <div className="col-2"></div>}
              </div>
              <div>
                {/* <div className="col-12">
                <p className="my-1 text-center">
                  By clicking <b>'Submit New Items'</b>. I agree and accept to
                  GoGMGo{" "}
                  <b onClick={() => setShowTermsPopup(true)}>Terms of Uses</b>{" "}
                  and{" "}
                  <b
                    onClick={() =>
                      window.open(
                        "https://www.gogmgo.com/privacy-policy/",
                        "_newtab"
                      )
                    }
                  >
                    Privacy Policy
                  </b>
                </p>
              </div> */}
              </div>
            </section>
            {/* account modal */}

            {/* {(showAccountPopUp === 0 || showAccountPopUp === 1) && ( */}
            <AccountPopup
              show={showAccountPopUp}
              tabType={accountTabType}
              onCancel={() => {
                setShowAccountPopUp(0);
                setAccountTabType(0);
              }}
            />
            {/* )} */}
            <PromocodePopup
              show={showPromocodePopup}
              onCancel={() => setShowPromocodePopup(0)}
            />
            <TipPopup
              show={showTipPopup}
              totalTip={totalTip}
              onCancel={() => setShowTipPopup(0)}
              tipChangeHandler={tipChangeHandler}
            />
            <Modal
              show={show_item_unavailable_modal}
              title={show_item_unavailable_text}
              OnSubmit={() => dispatch(dismissItemAvailableModal())}
              submitBtnText="ok"
            />
            <Modal
              show={showTermsPopup}
              title={""}
              extraClass={"top50"}
              modalbody={<Terms />}
              OnSubmit={() => setShowTermsPopup(false)}
              submitBtnText="ok"
            />
          </div>
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePaymentPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPaymentPage ? (
                <Payment
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPaymentPage={togglePaymentPopUp}
                />
              ) : null;
            }}
          </Motion>
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePayConfirmPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPayConfirmPage ? (
                <PaymentConfirm
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPayConfirmPage={togglePayConfirmPopUp}
                />
              ) : null;
            }}
          </Motion>
        </>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default Cart;

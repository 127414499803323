import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Motion, spring, presets } from "react-motion";

import {} from "../../Assets";
import { changePaymentDetail } from "../../Redux/actions";
import moment from "moment";
import {
  checkArray,
  checkNull,
  getInitialPaymentObj,
  getRandomString,
  parseRound,
  scrollToDiv
} from "../../helpers";
import PaymentConfirm from "./PaymentConfirm";

const PaymentSplitOptions = ({
  totalAmount,
  totalServiceCharge,
  totalSaleTax,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { splitType, wayToSplit, manyTimesToPay, paymentBody } = useSelector(
    (state) => state.paymentDetailReducer
  );

  const [slidePayConfirmPage, setSlidePayConfirmPage] = useState(false);
  const [showPayConfirmPage, setShowPayConfirmPage] = useState(false);

  const togglePayConfirmPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showPayConfirmPage) {
        setSlidePayConfirmPage(false);
        setTimeout(() => {
          setShowPayConfirmPage(!showPayConfirmPage);
        }, 500);
      } else {
        setShowPayConfirmPage(!showPayConfirmPage);
        setTimeout(() => {
          setSlidePayConfirmPage(true);
        }, 100);
      }
    },
    [showPayConfirmPage, slidePayConfirmPage]
  );

  const increOrDecreWayToSplit = (isDecrease = false) => {
    let newVal = wayToSplit;
    if (isDecrease) {
      if (wayToSplit > 2) {
        newVal -= 1;
      }
    } else {
      newVal += 1;
    }
    dispatch(changePaymentDetail({ wayToSplit: newVal }));
  };
  const increOrDecreManyTimesToPay = (isDecrease = false) => {
    let newVal = manyTimesToPay;
    if (isDecrease) {
      if (manyTimesToPay > 1) {
        newVal -= 1;
      }
    } else {
      if (manyTimesToPay < wayToSplit) {
        newVal += 1;
      }
    }
    dispatch(changePaymentDetail({ manyTimesToPay: newVal }));
  };

  const getSplitItems = () => {
    let res = [];
    if (checkArray(paymentBody?.menu_item)) {
      res = paymentBody?.menu_item.map((item) => {
        const modifierAddPrice = checkArray(item?.modifiers)
          ? item?.modifiers
              .filter((item) => item.action == "add" || item.action == "swap")
              .map((item) => item.modifier_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
        const modifierRemovePrice =
          checkArray(item?.modifiers) > 0
            ? item?.modifiers
                .filter((item) => item.action == "remove")
                .map((item) => item.modifier_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierSizePrice =
          checkArray(item?.modifiers) > 0
            ? item?.modifiers
                .filter((item) => item.action == "size")
                .map((item) => item.modifier_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierPricePer =
          modifierAddPrice - modifierRemovePrice + modifierSizePrice;
        const promoTotalDiscountPer = checkArray(item?.promotions)
          ? item?.promotions.reduce(
              (sum, promoItem) => sum + promoItem?.discountPerItem ?? 0,
              0
            )
          : 0;
        const discountPerItem =
          promoTotalDiscountPer > 0
            ? promoTotalDiscountPer
            : item?.discount > 0 && item?.menu_item_quantity > 0
            ? item?.discount / item?.menu_item_quantity
            : item?.discount;
        const menuItemPrice = parseFloat(item?.menu_item_price ?? 0);
        const menuTotalAmt = parseRound(
          (menuItemPrice + modifierPricePer - discountPerItem) *
            item?.menu_item_quantity +
            item?.serviceChargeAmount +
            item?.salesTaxAmount,
          4,
          true
        );
        const newItemObj = {
          menu_item_id: item?.menu_item_id,
          menu_item_name: item?.menu_item_name,
          quantity: 0,
          max_quantity: item?.menu_item_quantity,
          menu_item_price: item?.menu_item_price,
          void: item?.void,
          discount: discountPerItem,
          menu_item_type: item?.menu_item_type,
          actual_menu_price: item?.actual_menu_price,
          createDate: item?.createDate,
          promotions: item?.promotions ?? [],
          menu_service_charge:
            item?.serviceChargeAmount / item?.menu_item_quantity,
          menu_sales_tax: item?.salesTaxAmount / item?.menu_item_quantity,
          menu_total_price: menuTotalAmt / item?.menu_item_quantity,
        };
        if (item?.menu_item_type == "bundled") {
          newItemObj.BundledMenuItem = checkArray(item?.BundledMenuItem)
            ? item?.BundledMenuItem.map((bunItem) => ({
                id: checkNull(bunItem?.id),
                quantity: checkNull(bunItem?.quantity),
                menu_item_pos_name: checkNull(bunItem?.menu_item_pos_name),
                option_series_name: checkNull(bunItem?.option_series_name),
                action_price: checkNull(bunItem?.action_price),
                amount: checkNull(bunItem?.amount),
                bundledMenuitemModifers: checkArray(
                  bunItem?.bundledMenuitemModifers
                )
                  ? bunItem?.bundledMenuitemModifers.map((bunModifier) => ({
                      modifier_id: checkNull(bunModifier?.modifier_id),
                      modifier_quantity: checkNull(
                        bunModifier?.modifier_quantity
                      ),
                      modifier_price: checkNull(bunModifier?.modifier_price),
                      action: checkNull(bunModifier?.action),
                      unit_amount: checkNull(bunModifier?.unit_amount),
                      name: checkNull(bunModifier?.name),
                    }))
                  : [],
              }))
            : [];
        }
        return newItemObj;
      });
    }
    return res;
  };

  const calculateSplitDetail = () => {
    let res = [];
    const initialSplitObj = getInitialPaymentObj();

    if (splitType == 1) {
      const splitTotal = totalAmount / wayToSplit;
      const splitServiceCharge = totalServiceCharge / wayToSplit;
      const splitSalesTax = totalSaleTax / wayToSplit;
      for (let index = 1; index <= wayToSplit; index++) {
        const newSplitObj = {
          ...initialSplitObj,
          splitType: "Split Evenly",
          split_number: index,
          splitOfflineID: getRandomString(),
          splitPrice: splitTotal,
          service_charge: splitServiceCharge,
          sales_tax: splitSalesTax,
        };
        res.push(newSplitObj);
      }
    } else if (splitType == 2) {
      const splitTotal = totalAmount;
      const splitServiceCharge = totalServiceCharge;
      const splitSalesTax = totalSaleTax;
      const newSplitObj = {
        ...initialSplitObj,
        splitType: "Split By Item",
        split_number: 1,
        splitOfflineID: getRandomString(),
        splitPrice: splitTotal,
        service_charge: splitServiceCharge,
        sales_tax: splitSalesTax,
        split_items: getSplitItems(),
      };
      res.push(newSplitObj);
    } else if (splitType == 3) {
      const splitTotal = totalAmount;
      const splitServiceCharge = totalServiceCharge;
      const splitSalesTax = totalSaleTax;
      const newSplitObj = {
        ...initialSplitObj,
        splitType: "Split Manually",
        split_number: 1,
        splitOfflineID: getRandomString(),
        splitPrice: splitTotal,
        service_charge: splitServiceCharge,
        sales_tax: splitSalesTax,
      };
      res.push(newSplitObj);
    }
    return res;
  };

  return (
    <>
      <h3 class="title"> Split Options </h3>
      <div className="billing-list mx-n3 px-4 py-5 my-0">
        <p> How would you like to split the bill? </p>
        <button
          className={`blank_btn mb-2${splitType === 1 ? " active" : ""}`}
          onClick={() => {
            setTimeout(() => {
              scrollToDiv("middle-container");
            }, 100);
            dispatch(changePaymentDetail({ splitType: 1 }));
          }}
        >
          {" "}
          <span className="split-evenly sm-sq"> </span> Split evenly{" "}
        </button>
        <button
          className={`blank_btn mb-2${splitType === 2 ? " active" : ""}`}
          onClick={() => {
            setTimeout(() => {
              scrollToDiv("middle-container");
            }, 100);
            dispatch(changePaymentDetail({ splitType: 2 }));
          }}
        >
          {" "}
          <span className="split-item sm-sq"> </span> Split by item{" "}
        </button>
        <button
          className={`blank_btn mb-2${splitType === 3 ? " active" : ""}`}
          onClick={() => {
            setTimeout(() => {
              scrollToDiv("middle-container");
            }, 100);
            dispatch(changePaymentDetail({ splitType: 3 }));
          }}
        >
          {" "}
          <span className="split-amount sm-sq"> </span> Custom amounts{" "}
        </button>
      </div>
      {splitType === 1 && (
        <>
          <h3 class="title"> Split Evenly </h3>
          <div className="billing-list mx-n3 px-4 py-5 my-0">
            <div className="d-flex split-sec mt-4">
              <label className="col-8 pl-0">
                {" "}
                How many ways would you like to split the bill?{" "}
              </label>
              <div className="col-4 pr-0 d-flex counts-sec">
                <button
                  className="dec"
                  disabled={wayToSplit == 2}
                  onClick={() => increOrDecreWayToSplit(true)}
                >
                  {" "}
                </button>
                <strong className="count"> {wayToSplit} </strong>
                <button
                  className="inc"
                  onClick={() => increOrDecreWayToSplit()}
                >
                  {" "}
                </button>
              </div>
            </div>

            <div className="d-flex split-sec my-4">
              <label className="col-8 pl-0">
                {" "}
                How many ways will you be paying for?{" "}
              </label>
              <div className="col-4 pr-0 d-flex counts-sec">
                <button
                  className="dec"
                  disabled={manyTimesToPay == 1}
                  onClick={() => increOrDecreManyTimesToPay(true)}
                >
                  {" "}
                </button>
                <strong className="count"> {manyTimesToPay} </strong>
                <button
                  className="inc"
                  disabled={wayToSplit == manyTimesToPay}
                  onClick={() => increOrDecreManyTimesToPay()}
                >
                  {" "}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {splitType !== 0 && (
        <button
          className="input-btn mt-5 mb-3"
          onClick={() => {
            const splitDetails = calculateSplitDetail();
            dispatch(changePaymentDetail({ splitPaymentArr: splitDetails }));
            // history.push("/payment-confirm");
            togglePayConfirmPopUp();
          }}
        >
          {" "}
          Go To Pay
        </button>
      )}
      <Motion
        defaultStyle={{ x: 100 }}
        style={{
          x: spring(slidePayConfirmPage === true ? 0 : 100, {
            precision: 1,
            stiffness: 300,
            damping: 44,
          }),
        }}
      >
        {({ x }) => {
          return showPayConfirmPage ? (
            <PaymentConfirm
              style={{
                right: `-${x}%`,
              }}
              setShowPayConfirmPage={togglePayConfirmPopUp}
            />
          ) : null;
        }}
      </Motion>
    </>
  );
};

export default PaymentSplitOptions;
